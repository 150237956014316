@import '../../styles/global.scss';
@import '../../styles/colors.scss';


.collapsePanel {
  background-color: $primary_25 !important;
}

.icons {
  height: 20px;
  width: 20px;
  margin-top: 5px;
  stroke: $terciary_200;
  cursor: pointer;
}
.formQuota {
  margin-top: 1rem;
  button {
    padding: 15px 55px;
    font-size: 14px;
    font-weight: 600;
  }
  label {
    font-weight: 600;
    font-size: 14px !important;
  }
}

.button {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.endList {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $primary_25;
  margin-bottom: 1rem;
  padding: 1rem;
  border-top: 2px solid $primary_300;
}

.bodyEndList {
  height: auto;
  @include center();
  color: $primary_400;
  padding: 24px;
  flex-direction: column;
  background-color: $primary_25;
  text-align: center;
  row-gap: 2rem;
}

.filters{
  width: 100%;
 display: flex;
 align-items: center;
 justify-content: flex-end;
 column-gap: 2rem;
 margin-bottom: 1.8rem;
 div{
   display: flex;
   align-items: center;
   column-gap: 0.5rem;
  
   article{
     color: #32569A!important;
     font-weight: 600;
     font-size: 15px;
   }
 }
}


.inputSearch{
  margin-bottom: 0rem;
  button{
    background-color: $terciary_200!important;
  }
}

.inputSearchGral{
  margin-bottom: 2rem;
  button{
    background-color: $terciary_200!important;
  }
}
