@import '../../styles/colors.scss';
@import '../../styles/global.scss';

.EmptyListMessage {
  @include center();
  flex-direction: column;
  gap: 20px;
  height: 268px;
  width: 100%;
  background-color: $primary_25;
  color: $primary_400;
  .Icon {
    height: 64px;
    width: 64px;
    border: 1px solid $primary_75;
    @include center();
    border-radius: 50%;
    color: $terciary_200;
  }
}
