@import '../../../styles/colors.scss';

@mixin button-default-properties() {
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition-duration: 0.3s;
  min-width: fit-content;
  border-radius: 2px;
  font-size: 1rem;
  font-weight: 600;
  padding: 4px 15px;
  column-gap: 1rem;
}

@mixin disabled-button-square() {
  background: $neutral_150;
  color: #ffffff;
  border: 1px solid $neutral_150;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}

.button-red {
  @include button-default-properties();
  border: none;
  background: $extra_150;
  color: #ffffff;
}
.button-red:hover {
  background: $terciary_150;
  color: #ffffff;
}
.button-red:active {
  background: $terciary_400;
  color: #ffffff;
}
.button-red:disabled,
button-red[disabled] {
  @include disabled-button-square();
}

.button-blue {
  @include button-default-properties();
  color: white;
  background: $terciary_200;
  border: 1px solid $terciary_150;
}

.button-blue:hover {
  background: $terciary_150;
  color: #ffffff;
}
.button-blue:active {
  background: $terciary_400;
  color: #ffffff;
}
.button-blue:disabled,
button-blue[disabled] {
  @include disabled-button-square();
}

.button-grey {
  @include button-default-properties();
  color: $neutral_600;
  background: white;
  border: 1px solid $neutral_55;
}

.button-grey:hover {
  background: $secondary_200;
  color: #ffffff;
}
.button-grey:active {
  background: $secondary_400;
  color: #ffffff;
}
.button-grey:disabled,
button-grey[disabled] {
  @include disabled-button-square();
}

.button-transparent {
  @include button-default-properties();
  color: $extra_150;
  background: transparent;
  border: 1px solid $extra_150;
  cursor: pointer;
}

.button-transparent:hover {
  background: transparent;
  color: $extra_300;
}
.button-transparent:active {
  background: transparent;
  color: $extra_400;
}
.button-transparent:disabled,
button-transparent[disabled] {
  @include disabled-button-square();
}

.button-transparentBlue {
  @include button-default-properties();
  color: $terciary_200;
  background: transparent;
  border: 1px solid $terciary_200;
  cursor: pointer;
}

.button-transparentBlue:hover {
  background: $terciary_300;
  color: #ffffff;
}
.button-transparentBlue:active {
  background: $terciary_400;
  color: #ffffff;
}
.button-transparentBlue:disabled,
button-transparentBlue[disabled] {
  @include disabled-button-square();
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin loader-default($w: 1rem, $h: 1rem) {
  border: 2px solid $terciary_200;
  border-bottom-color: transparent;
  border-radius: 999px;
  animation: rotate 1s infinite ease-out;
  width: $w;
  height: $h;
}

.button-loader-sm {
  @include loader-default(0.5rem, 0.5rem);
}
.button-loader-m {
  @include loader-default();
}
.button-loader-x {
  @include loader-default(2rem, 2rem);
}

.rounded-full {
  border-radius: 9999px;
  column-gap: 0.5rem;
  padding-inline: 2rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  font-family: 'MuseoSansRegular';
}

.button-sm {
  height: 1.75rem;
}
.button-m {
  height: 3rem;
}

.icon-rounded-size-sm {
  width: 1rem;
  height: 1rem;
}
.icon-rounded-size-m {
  width: 1.5rem;
  height: 1.5rem;
}

.primary-color {
  border: none;
  background: $terciary_200;
  color: #ffffff;
}
.primary-color:hover {
  background: $terciary_100;
  color: #ffffff;
}
.primary-color:active {
  background: $terciary_400;
  color: #ffffff;
}
.primary-color:disabled,
primary-color[disabled] {
  background: $neutral_150;
  color: #ffffff;
}

.primary-white {
  border: $terciary_200 2px solid;
  background: #ffffff;
  color: $terciary_200;
}
.primary-white:hover {
  background: $terciary_25;
  color: $terciary_200;
}
.primary-white:active {
  background: $terciary_200;
  color: #ffffff;
}
.primary-white:disabled,
primary-white[disabled] {
  border: solid 2px $neutral_150;
  background: $neutral_50;
  color: #ffffff;
}

.icon-primary-color {
  stroke: red;
}

.secondary-color {
  border: none;
  background: $extra_150;
  color: #ffffff;
}
.secondary-color:hover {
  background: $extra_75;
  color: #ffffff;
}
.secondary-color:active {
  background: $extra_300;
  color: #ffffff;
}
.secondary-color:disabled,
secondary-color[disabled] {
  background: $neutral_150;
  color: #ffffff;
}

.secondary-white {
  border: $extra_150 2px solid;
  background: #ffffff;
  color: $extra_150;
}
.secondary-white:hover {
  background: $extra_25;
  color: $extra_150;
}
.secondary-white:active {
  background: $extra_150;
  color: #ffffff;
}
.secondary-white:disabled,
secondary-white[disabled] {
  border: none;
  background: $neutral_50;
  color: #ffffff;
}

.tertiary-color {
  border: none;
  background: $secondary_300;
  color: #ffffff;
}
.tertiary-color:hover {
  background: $secondary_150;
  color: #ffffff;
}
.tertiary-color:active {
  background: $secondary_500;
  color: #ffffff;
}
.tertiary-color:disabled,
tertiary-color[disabled] {
  background: $neutral_150;
  color: #ffffff;
}

.tertiary-white {
  border: $secondary_300 2px solid;
  background: #ffffff;
  color: $secondary_300;
}
.tertiary-white:hover {
  background: $secondary_25;
  color: $secondary_300;
}
.tertiary-white:active {
  background: $secondary_300;
  color: #ffffff;
}
.tertiary-white:disabled,
tertiary-white[disabled] {
  border: none;
  background: $neutral_50;
  color: #ffffff;
}

.icons-default {
  width: 1rem;
  height: 1rem;
}
.w-100 {
  width: 100%;
  justify-content: center;
  font-size: 16px !important;
}