@import '../../../../styles/colors.scss';

.div-container {
  width: 64px;
  height: 6.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.selected-div {
  width: 4rem;
  height: 4rem;
  border: 4px solid #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.selected {
  border: 2px solid $terciary_200;
  border-radius: 0.5rem;
}

.div-hover {
  &:hover {
    border-radius: 0.5rem;
    border-color: $terciary_200;
  }
}
.div-border {
  border: 2px solid transparent;
}

.img-option {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.2rem;
}

.P-title {
  display: flex;
  column-gap: 0.5rem;
  width: fit-content;
  margin: 0;
  font-family: 'MuseoSansBold';
  align-items: center;
  margin-top: 1rem;
}

.no-wrap {
  white-space: nowrap;
}

.question-icon {
  width: 1rem;
  height: 1rem;
  color: $secondary_200;
  cursor: pointer;
}

.div-pop-father {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-pop-tootltip {
  position: absolute;
  bottom: 130%;
  width: 100%;
}

.tooltip {
  position: relative;
  width: 100%;
  height: 100%;
}

.pop-square {
  position: absolute;
  width: 1rem;
  height: 1rem;
  transform: rotate(45deg);
  background: $secondary_200;
  bottom: 0%;
}
.pop-lorem {
  position: absolute;
  width: 12.5rem;
  background: $secondary_200;
  bottom: 140%;
  left: -370%;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: #ffffff;
}

.tooltip-label {
  margin: 0;
  font-weight: 600;
  font-family: 'MuseoSansBold';
  font-size: 0.75rem;
}
.title-label {
  margin: 0;
  font-weight: 600;
  font-family: 'MuseoSansBold';
  font-size: 0.75rem;
  color: $neutral_600;
  text-align: center;
  user-select: none;
}

.color-transparent {
  color: transparent;
}

.optional-title {
  color: $primary_200;
  margin: 0;
  margin-bottom: 0.375rem;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  font-family: 'MuseoSansRegular';
  white-space: nowrap;
}
