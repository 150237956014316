@import '../../../styles/colors.scss';
.update-input {
  position: relative;
  .dinamic-input-container {
    position: relative;
    input {
      border: none;
      border-bottom: 1px solid $neutral_100;
    }
    svg {
      position: absolute;
      right: 0;
      width: 20px;
      stroke: $terciary_200;
    }
  }
  .static-input {
    border: none;
    cursor: pointer;
    &:hover {
      background-color: $neutral_55;
      border-bottom: 1px solid $neutral_100;
    }
  }
}
