@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

.GradesTitle {
  color: $primary_200;
}

.createLevelModal {
  .createLevelModalFooter {
    display: flex;
    justify-content: flex-end;
  }
  .createLevelModalbody {
    padding: 20px;
  }
}

.redirectOptions {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(22, 54, 112, 0.24);
  border-radius: 16px;
  display: flex;
  padding: 19px 21px;
  cursor: pointer;
  margin-top: 16px;
  .left{
    width: 90%;
    display: flex;
    gap: 21px;
  }
  .right{
    width: 10%;
    @include center()
  }
}
