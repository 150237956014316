@import '../../../styles/global.scss';
@import '../../../styles/colors.scss';

.uploadMassive {
  height: auto;
  @include center();
  color: $primary_400;
  padding: 24px;
  flex-direction: column;
  background-color: $primary_25;

  .text{
    margin: 2rem 0;
  }

  .uploadContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;


    .upload{
     
      width: 320px;
    }

    .label {
      font-weight: 600;
    }

    .asterik {
      color: $extra_150;
    }
    .button {
      width: 310px;

      .iconUpload {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .formats{
    
    color: rgba(0, 0, 0, 0.45);
    line-height: 14px;
    margin-top: 0.5rem;
    width: 100%;
  }

  .template{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
    justify-content: center;
    row-gap: 1.5rem;
    text-align: center;

    .btn{
      color:$terciary_200;
      font-weight: 500;
    }
  }
}

.btnUpload{
  display: flex;
  align-items: center;
  padding: 1rem 0 0 0;
  justify-content: flex-end;
}

.contentErrors{
  display: flex;
  align-items: center;
   width: 100%;
  column-gap: 0.5rem;
  .iconError{
    height: 20px;
    width: 20px;
    stroke: red;
    cursor: pointer;
  }
  
  .spanError{
    color:$primary_300;
        font-weight: 500;
  }
}


