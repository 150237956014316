.pestana {
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  padding: 0.3rem;
  background: white;
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  width: 12rem;
  
}



.icons-hero {
  stroke: black;
  width: 1rem;
  height: 1rem;
}
.text-icon {
  border: 2px dotted;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.img-little {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: relative;
}

.flex-component {
  display: flex;
  column-gap: 0.5rem;
}

.eye-button {
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.img-show {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tooltip-my {
  border: 1px solid;
  position: absolute;
  bottom: 110%;
  width: 50px;
  height: 100px;
}

.p-title {
  margin: 0;
  padding: 0;
  font-size: 0.7em;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 72%;
  overflow: hidden;
}
.buttons-layers {
  display: flex;
  margin-bottom: 0.5rem;
  column-gap: 0.2rem;
}

.padding {
  padding: 0.3rem;
}
