@import '../../styles/colors.scss';

.first-time-container{
    display: flex ;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 500px;
    gap: 8px;


    color: $terciary_600;
    background: #ffffff;
    border-radius: 16px;
    padding: 29px;
    filter: drop-shadow(0px 1px 4px rgba(22, 54, 112, 0.24));
}