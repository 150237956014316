@import '../../styles/colors.scss';
.prebuyFooter {
  .title {
    display: flex;
    gap: 22px;
    margin-bottom: 20px;
    color: white;
    align-items: center;
  }
  .subTitle {
    margin-bottom: 20px;
    color: $secondary_150;
  }
  .description {
    margin-bottom: 20px;
    color: white;
  }
  .topics {
    background: rgba(243, 247, 255, 0.16);
    border: 4px solid $primary_25;
    border-radius: 8px;
    min-height: 240px;
    margin-bottom: 20px;
    color: $primary_25;
    padding: 24px;
    padding-left: 58px;
    li {
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
    }
  }
  .buttons{
    width: 100%;
    button{
        width: 100%;
        justify-content: center;
        font-weight: 600;
font-size: 16px;
line-height: 18px;
    }
  }
}
