.div-container-external {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.div-options-container {
  display: flex;
}
.div-optionsize-container {
  display: flex;
}

.div-fonts {
  position: relative;
  background: white;
}
.div-fonts:hover {
  position: relative;
  background: #d9d9d9;
}

.p-fonts {
  margin: 0.2rem;
  padding-inline: 0.2rem;
  white-space: nowrap;
  user-select: none;
}
.p-fonts:hover {
  background: #d9d9d980;
  padding-inline: 0.2rem;
  cursor: pointer;
}
.font-fam-p {
  margin: 0;
  padding-inline: 0.5rem;
  display: flex;
  height: 1.75rem;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  cursor: pointer;
  user-select: none;
}
.selected-font {
  background: #d9d9d980;
}

.p-cursos-click {
  cursor: pointer;
}

.son-custom {
  width: 12rem;
}
.input-color {
  height: 1.75rem;
  min-width: 1.75rem;
  width: 1.75rem;
}

.input-art {
  outline: none;
  width: auto;
}
.input-size {
  border: 1px solid black;
  width: 5rem;
  text-align: center;
  outline: none;
}
.input-school {
  width: auto;
  padding-inline: 3rem;
}

.input-size::-webkit-outer-spin-button,
.input-size::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-size[type='number'] {
  -moz-appearance: textfield;
}

.custom-button {
  padding-inline: 0.5rem;
}

.div-fonts-container {
  position: absolute;
  top: 0;
  left: 100%;
  border: 1px solid #d9d9d9;
  background: white;
  border-radius: 0.25rem;
}
.p-option {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  height: 1.3rem;
  user-select: none;
}

.hero-icon {
  height: 1rem;
}
//----------------------- para dropdown
.Arial {
  font-family: Arial, Helvetica, sans-serif;
}
.Alkatra {
  font-family: 'AlkatraRegular';
}
.Courier {
  font-family: 'Courier New', Courier, monospace;
}
.Franklin {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.Gill {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.Lucida {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.Segoe {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.Times {
  font-family: 'Times New Roman', Times, serif;
}
.Trebuchet {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.Cambria {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.Georgia {
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.Impact {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.Verdana {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.cursive {
  font-family: cursive;
}
.fantasy {
  font-family: fantasy;
}
.monospace {
  font-family: monospace;
}
.sans-serif {
  font-family: sans-serif;
}
.serif {
  font-family: serif;
}
.system-ui {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.Concert-One {
  font-family: 'Concert-One';
}

//Roboto
.Roboto-Thin {
  font-family: 'Roboto-Thin';
}
.Roboto-Light {
  font-family: 'Roboto-Light';
}
.Roboto-Regular {
  font-family: 'Roboto-Regular';
}
.Roboto-Medium {
  font-family: 'Roboto-Medium';
}
.Roboto-Bold {
  font-family: 'Roboto-Bold';
}
//Roboto
//open sans

.OpenSans-Light {
  font-family: 'OpenSans-Light';
}
.OpenSans-Regular {
  font-family: 'OpenSans-Regular';
}
.OpenSans-Medium {
  font-family: 'OpenSans-Medium';
}
.OpenSans-SemiBold {
  font-family: 'OpenSans-SemiBold';
}
.OpenSans-Bold {
  font-family: 'OpenSans-Bold';
}

//open sans

//-----------------------
.AlkatraBold {
  font-family: 'AlkatraBold';
}
.AlkatraMedium {
  font-family: 'AlkatraMedium';
}
.AlkatraRegular {
  font-family: 'AlkatraRegular';
}
.AlkatraSemibold {
  font-family: 'AlkatraSemibold';
}

//pruebas misticas

.div-container {
  border: 2px solid red;
  height: 100vh;
}

.svg {
  width: 100%;
  height: 100%;
}

.text {
  text-align: justify;
}

.padre {
  border: 2px solid purple;
  width: 30%;
}
