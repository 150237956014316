.Father-class {
  position: relative;
  width: fit-content;
}

.Son-class {
  position: absolute;
  width: 100%;
  z-index: 5;
}
