@import '../../../styles/colors.scss';
.CreateGradeModal {
  .createGradeFooter {
    display: flex;
    justify-content: flex-end;
  }
  .createGradeBody {
    padding: 20px;
  }
}

.iconStyle {
  height: 20px;
  width: 20px;
  margin-top: 5px;
  stroke: $terciary_200;
  cursor: pointer;
  &.red {
    stroke: $extra_150;
  }
  &.iconStyleFill {
    stroke: none;
    fill: $terciary_200;
  }
}
