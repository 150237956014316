@import '../../styles/colors.scss';
@import '../../styles/global.scss';
$footerHeight: calc(48px + 32px);
$bodyHeight: 100%;
$adminBodyHeight: calc(100% - $footerHeight);

.custom-comment-drawer {
  &.admin {
    .tickets-container {
      height: 90%;
      height: $adminBodyHeight;
    }
  }
  .see-details {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: $primary_300;
      text-decoration: underline;
      margin: 0;
      cursor: pointer;
      &:hover {
        color: $primary_200;
      }
    }
  }
  .ant-drawer-body {
  }
  .tickets-container {
    width: 100%;
    height: 90%;
    height: $bodyHeight;
    display: flex;
    flex-direction: column;
    overflow: auto;
    button {
      width: 100%;
      @include center();
    }
    textarea {
      min-height: 72px;
    }
    .ticket-card {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border: 2px solid #f3f7ff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      /* border-radius: 16px 16px 0 0; */
      border-radius: 16px;
      margin-bottom: 16px;
      background-color: white;

      .title {
        display: flex;
        justify-content: space-between;
        p {
          color: $primary_600;
        }
        label {
          background: #fff6ee;
          border-radius: 16px;
          width: 96px;
          text-align: center;
          color: #f17400;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          padding: 4px 0;
          &[data-variation='2'] {
            background: #eefffc;
            color: #2fb06a;
          }
        }
        .user {
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $neutral_150;
            width: 60%;
            &:nth-child(odd) {
              color: $primary_600;
              font-size: 16px;
              line-height: 18px;
            }
          }
          margin-bottom: 8px;
        }
        .check{
          width: 30%;
        }
      }

      .description {
        overflow-wrap: break-word;
        color: $primary_400;
        .images{
          display: flex;
          gap: 16px;
          margin: 10px 0;
          img{
            width: 28%;
            aspect-ratio: 3/2;
            object-fit: contain;
            &:hover{
              filter: drop-shadow(0 1px 5px rgba(155, 155, 155, 0.344));
              cursor: pointer;
            }
          }
        }
      }
    }

    /* .containerCollapsible {
      margin-bottom: 16px;
      .collapsible {
        background-color: $primary_25;
        border-radius: 0 0 16px 16px;
        color: $primary_400;
        padding: 18px;
        width: 100%;
        text-align: left;
        outline: none;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.1s linear;

        &:hover {
          background-color: $primary_75;
        }
      }
      .collapseTitle {
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        .chevron {
          width: 20px;
          height: 20px;
          transition: all 0.3s ease-out;
        }
      }
      .collapsibleContent {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 0 0 16px 16px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.1s ease-out;
        background-color: white;
        .body {
          padding: 16px;
          gap: 16px;
          display: flex;
          flex-direction: column;
          .user {
            span {
              color: rgba(0, 0, 0, 0.45);
              &:nth-child(odd) {
                color: $neutral_150;
              }
            }
            margin-bottom: 8px;
          }
          .comment {
            color: $primary_600;
            margin-bottom: 8px;
          }
          button {
            width: 100%;
            @include center();
          }
        }
      }
    } */
  }
  .comments-footer-button {
    height: $footerHeight;
    display: flex;
    align-items: center;
    button {
      width: 100%;
      @include center;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: $terciary_25;
    }
  }

  .uploaded-images-container {
    display: flex;
    flex-direction: row;
    margin: 8px;
    border: 1px solid black;
    border-radius: 16px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon-container {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}
