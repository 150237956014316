@import '../../../styles/global.scss';
@import '../../../styles/colors.scss';

.container{
    height: auto;
   
    color: $primary_400;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    background-color: $primary_25;

    .authTable{
    
       min-height: 400px;
       overflow: auto;
    }
}

.button{
    display: flex;
    align-items: center;
    padding: 1rem 0 0 0;
    column-gap: 0.5rem;
    justify-content: flex-end;
  }
  