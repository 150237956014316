.H {
  margin: 0;
  color: currentColor;
  &[data-variation='1'] {
    font-size: 48px;
    line-height: 46px;
  }
  &[data-variation='2'] {
    font-size: 40px;
    line-height: 40px;
  }
  &[data-variation='3'] {
    font-size: 32px;
    line-height: 37px;
     max-width: 490px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &[data-variation='4'] {
    font-size: 28px;
    line-height: 28px;
  }
  &[data-size='Bold'] {
    font-family: 'MuseoSansBold';
  }
  &[data-size='Light'] {
    font-family: 'MuseoSansLight';
  }
}
