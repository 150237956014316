@import '.././../../styles/colors';
.searchContainer {
  margin: 0.5rem 0 2.5rem 0;
  width: 100%;
  height: 40px;

  .autoComplete {
    width: 100%;
    input {
      height: 100%;
    }
    button {
      background-color: $terciary_200;
    }
  }
}
