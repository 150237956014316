@import '../../../styles/colors.scss';
@import '../../../styles/responsiveSize';

.body-div {
  padding-top: 3.5rem;
}
.div-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100vh;
  height: fit-content;
}
.div-left {
  height: 100%;
  position: relative;
}

.div-left-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  .edit-button-container {
    z-index: 10;
    margin-left: 6%;
    margin-right: 6%;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    @include media('fullscreen') {
      margin-bottom: 100px;
    }
    @include media('desktop') {
      margin-bottom: 120px;
    }
   
    @include media('tablet') {
      margin-bottom: 140px;
    }
    @include media('mobile') {
      margin-bottom: 160px;
    }
  }
}

.div-select-options {
  padding-inline: 2rem;
  padding-top: 5.5rem;
  padding-bottom: 8.188rem;
}

.div-title-type {
  background: $primary_25;
  padding: 1.5rem;
  font-family: 'MuseoSansBold';
  border-radius: 1rem;
}
.title-h {
  font-family: 'MuseoSansBold';
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
  color: $primary_500;
}

.description-title {
  font-family: 'MuseoSansRegular';
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 4%;
  color: $neutral_200;
  margin: 0;
}

.img-inside {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.div-img-container {
  width: 100%;
  height: 40.752vw;
  position: sticky;
  top: 10rem;
}

.p-label {
  font-family: 'MuseoSansRegular';
  font-weight: 400;
  font-size: 1.125rem;
  color: $extra_200;
}

.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  margin: 12px 12px;
}

.preview-container-title {
  color: #7fa5ea;
  text-align: left;
  width: 100%;
  margin-bottom: 16px;
}

.carrousel-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
}

.arrow-button {
  border: none;
  box-shadow: none;
  outline: none;
  padding: 8px;
  margin: 0px;
  background-color: transparent;
  cursor: pointer;
  color: #0f48ff;
}

.tab-item {
  display: inline-block;
  background-color: #0f48ff;
  height: 4px;
  width: 24px;
  opacity: 0.3;
  margin: 0px 3px;
  border-radius: 2px;

  &.selected {
    opacity: 1;
    width: 42px;
  }
}

.book-items-container {
  margin-top: -12rem;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  
  .book-item {
    /* transition: width 0.3s linear; */
    margin-bottom: 10px;
    overflow: hidden;
    width: 0%;
    height: 100%;

    &.show {
      width: 100%;
    }
  }
}

.div-sticky{
  position: sticky;
  top: 0;
  right: 0;
  border: 2px solid;
}