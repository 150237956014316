.durarion {
  transition-duration: 1000ms;
  opacity: 0;
  display: none;
}

.display-show {
  display: block;
}

.display-full-opacity {
  opacity: 1;
}

.upload-message-container {
  background: #efefef;
  padding-inline: 0.625rem;
  font-family: 'MuseoSansBold';
  padding: 2rem;
  margin-top: 4.313rem;
  border-radius: 1rem;
}
.upload-message-p {
  font-family: 'MuseoSansRegular';
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

.label-propuesta {
  display: flex;
  align-items: center;
  column-gap: 2.625rem;
  margin-top: 2rem;
  cursor: pointer;
  width: fit-content;
}

.margin-top-button {
  margin-top: 2rem;
}

.img-logo-dragable {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.label-spine-color{
  display: flex;
  column-gap: 1rem;
  align-items: center;
}