@import '../../../styles/colors.scss';

@mixin absolute-zIndex($z) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: $z;
}

.body-div {
  padding-top: 3.5rem;
}

.div-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100vh;
  height: fit-content;
}

.div-border {
  border: 1px solid #000000;
}

.div-left {
  display: flex;
  justify-content: center;
  height: 100%;
}

.div-select-options {
  padding-inline: 2rem;
  padding-top: 5.5rem;
  padding-bottom: 8.188rem;
}

.div-title-type {
  background: $primary_25;
  padding: 1.5rem;
  font-family: 'MuseoSansBold';
  border-radius: 1rem;
  margin-top: 1rem;
}

.title-h {
  font-family: 'MuseoSansBold';
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
  color: $primary_500;
}

.description-title {
  font-family: 'MuseoSansRegular';
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 4%;
  color: $neutral_200;
  margin: 0;
}
