@import 'styles/colors.scss';
@import 'styles/global.scss';
@import 'styles/responsiveSize.scss';
.navbar {
  display: flex;
  justify-content: space-between;
  background-color: $primary_600;
  padding: 16px 48px;
  height: $navbar_height;
  position: fixed;
  z-index: 100;
  top: 0;
  width: calc(100% - $sidebar_width);
  margin-left: $sidebar_width;
  transition: all 0.2s;
  color: white;
  .right {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
  }
  span {
    color: white;
  }
  @include media('tablet') {
    width: 100%;
    margin-left: 0;
  }

  &[data-variation='complete'] {
    left: 0;
    width: 100vw;
    margin-left: 0;
  }
}
