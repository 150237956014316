@import '../../../styles/global.scss';

.customSelect {
  & > div {
    height: 40px !important;
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      margin-top: 5px;
      font-family: 'MuseoSansLight';
    }
  }
}
