.AdminUtilsManagement {
  width: 100%;
}

.custom-collapse-bg {
  background: #f3f7ff80;
}

.button-margin {
  margin-block: 1rem;
}

.empty-notebooks {
  margin: 0;
  padding: 0;
  color: red;
  font-size: 2rem;
  font-family: 'MuseoSansRegular';
}

.input-notebook {
  height: 2.7rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #d9d9d9;
  padding-left: 1rem;
  font-family: 'MuseoSansRegular';
  font-size: 1rem;
  outline: none;
  margin-bottom: 1rem;
}
.input-notebook:focus {
  border: 1px solid blue;
}
