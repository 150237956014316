@import '../../../styles/colors.scss';

@mixin flex-base {
  display: flex;
  align-items: center;
}

.body-div {
  padding-inline: 3rem;
  padding-top: 4.5rem;
}

.uniform-icon {
  width: 2rem;
  height: 2rem;
}

.uniform-circle-icon {
  width: 2.5rem;
  height: 2.5rem;
}
.div-circle {
  @include flex-base();
  justify-content: center;
  border: solid 1px #c9dcff;
  border-radius: 999px;
  width: 4rem;
  height: 4rem;
}

.P-title {
  font-weight: 600;
  font-size: 2rem;
  cursor: text;
  margin: 0;
  color: #305599;
}
.P-info {
  font-weight: 600;
  font-size: 1.25rem;
  cursor: text;
  margin: 0;
  color: #305599;
}

.div-Title {
  @include flex-base;
  grid-column-gap: 1rem;
  justify-content: space-between;
}

.div-list {
  margin-top: 3rem;
  height: 16.75rem;
  width: 100%;
  background: #f3f7ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.button-custom {
  width: 15rem;
  height: 3rem;
}

.colorr {
  color: white;
  width: 1rem;
  height: 1rem;
}

.div-max-heigh {
  max-height: 350px;
  overflow-y: scroll;
  margin-top: 1rem;
}

.label-modal {
  font-family: 'MuseoSansRegular';
  font-size: 1rem;
  color: $primary_600;
  padding: 0;
  margin: 0;
  margin-top: 2.5rem;
}

.margen-top-gender {
  margin-top: 3.563rem;
}

.buttons-gender {
  display: flex;
  column-gap: 1rem;
  margin-top: 1rem;
}

@mixin button_default($col) {
  height: 2rem;
  padding-inline: 1rem;
  font-family: 'MuseoSansRegular';
  font-size: 0.875rem;
  font-weight: 400;
  color: $col;
}

.button-male-female-selected {
  @include button_default(white);
  background-color: #1890ff;
  border-radius: 2px;
  border: 1px solid #1890ff;
}
.button-male-female-unselected {
  @include button_default(black);
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}
