@import '../../styles/colors.scss';

@mixin props {
  border-radius: 16px;
  padding: 0.4rem 1rem;
  margin-top: 0.4rem;
  color: #fff;
}
.tag {
  border: none;

  font-size: 16px;
  .status {
    @include props;

    &[data-variation='1'] {
      background-color: #0cccf6;
    }
    &[data-variation='2'] {
      background-color: #f7a70c;
    }
    &[data-variation='3'] {
      background-color: #0090d3;
    }
    &[data-variation='4'] {
      background-color: #c2da3a;
    }
    &[data-variation='5'] {
      background-color: #00d355;
    }
    &[data-variation='6'] {
      background-color: #2FABB0;
    }
    &[data-variation='7'] {
      background-color: #0090d3;
    }
    &[data-variation='8'] {
      background-color: #0090d3;
    }
    &[data-variation='9'] {
      background-color: #0090d3;
    }
    &[data-variation='10'] {
      background-color: #0cccf6;
    }
    &[data-variation='11'] {
      background-color: #0cccf6;
    }
    &[data-variation='12'] {
      background-color: #0cccf6;
    }
  }
}
