@import '../../../styles/global.scss';
@import '../../../styles/colors.scss';

.utilstable {
    width: 100% !important;
}

.table-container {
    table {
        tr{
            cursor: pointer;
        }
    }
}