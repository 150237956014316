@import '../../../styles/global.scss';
@import '../../../styles/colors.scss';

@mixin props {
  border-radius: 16px;
  padding: 0.4rem 1rem;
  margin-top: 0.4rem;
  color: #fff;
}


.tag {
    border: none;
  
    font-size: 16px;
    .status {
      @include props;
  
     
      &[data-variation='6'] {
        background-color: #2FABB0;
      }
      &[data-variation='7'] {
        border: #2FABB0 2px solid;
        background: #ffffff;
        color: #2FABB0;
      }
    }
  }
  