@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

.CreateSchoolForm {
 
  label {
    color: $primary_600 !important;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 18px;
  }
}

.deletedModal{

  .deleteModalFooter {
    display: flex;
    justify-content: flex-end;
  }
  .deleteModalBody {
    height: 256px;
    @include center();
    color: $primary_400;
    padding: 24px;
    flex-direction: column;
    gap: 34px;
    background-color: $primary_25;
  }
}