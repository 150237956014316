@import '../../../styles/global.scss';
@import '../../../styles/colors.scss';

.CardsContainer {
  display: flex;
  gap: 16px;

  .card {
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 26px;
    padding: 29px;
    width: 209px;
    height: 209px;
    filter: drop-shadow(0px 1px 4px rgba(22, 54, 112, 0.24));
    color: $terciary_600;
    &:hover {
      filter: drop-shadow(0px 1px 16px rgba(22, 54, 112, 0.24));
      cursor: pointer;
    }
    &[data-variation='2'] {
      background-color: #0cccf6;
    }
    &[data-variation='4'],
    &[data-variation='5'] {
      background-color: #4bb543;
    }
  }
}
