.div-container-editor {
  height: 500px;
  width: 100%;
  border: 1px solid black;
  position: relative;
}
.div-container-editor-covers {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 111111;
}

.div-movible {
  border: 2px dotted black;
  width: 100px;
  height: 100px;
  user-select: none;
  position: absolute;
  cursor: move;
}

.div-movile-notdotted {
  border: 2px dotted transparent;
}

.div-interno-dragable {
  width: 100%;
  height: 100%;
  position: relative;
}

.div-onload {
  width: 50px;
  height: 50px;
  opacity: 0;
}

.div-rotate-me {
  position: absolute;
  left: 100%;
  bottom: 100%;
  width: 20px;
  height: 20px;
  border-radius: 999px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  background: white;
}
.div-save-me {
  position: absolute;
  right: 100%;
  bottom: 100%;
  width: 20px;
  height: 20px;
  border-radius: 999px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid blue;
  background: white;
}

.div-scale-me {
  position: absolute;
  left: 100%;
  top: 100%;
  width: 20px;
  height: 20px;
  border-radius: 999px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  transform: rotate(90deg);
  background: white;
}
.div-close-me {
  position: absolute;
  right: 100%;
  top: 100%;
  width: 20px;
  height: 20px;
  border-radius: 999px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  background: white;
}

.icon-action {
  width: 80%;
  height: 80%;
}

.none-disp {
  display: none;
}

.img-logo-dragable {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.style-z-index {
  z-index: 9999999999;
}
