@import 'styles/colors.scss';
@import 'styles/global.scss';
@import 'styles/responsiveSize.scss';

.mainDashboard {
  .content {
    transition: all 0.2s;
    margin-top: $navbar_height;
    padding: 72px 48px;
    margin-left: $sidebar_width;
    @include media('tablet') {
      margin-left: 0;
      width: 100%;
    }
    &[data-variation='bkg'] {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: calc(100vh - $navbar_height);
      padding: 72px 120px;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
