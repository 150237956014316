@import '../../../styles/colors.scss';
.icons{
  height: 20px;
  width: 20px;
  margin-top: 5px;
  stroke: $terciary_200;
  cursor: pointer;
}

.actions{
  width: 100%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: space-between;
 column-gap: 4rem;
}

@mixin props {
  border-radius: 16px;
  padding: 0.4rem 1rem;
  margin-top: 0.4rem;
  color: #fff;
}

.tag {
  border: none;

  font-size: 16px;
  .status {
    @include props;

   
    &[data-variation='6'] {
      background-color: #2FABB0;
    }
    &[data-variation='7'] {
      border: #2FABB0 2px solid;
      background: #ffffff;
      color: #2FABB0;
    }
  }
}
