@import '../../../styles/colors.scss';
.module-container {
  background: #ffffff;
}

.form-user {
  display: grid;
  grid-template-columns: 50% 50%;
}

.div-input-container {
  padding-inline-end: 2rem;
}

.p-text-title {
  font-size: 1rem;
  color: $primary_600;
  font-weight: 600;
  font-family: 'MuseoSansRegular';
  margin: 0;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.div-permisions-g5 {
  padding-inline: 1rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.div-permisions-g6 {
  padding-inline: 1rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.label-option {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.descriptionstyle {
  width: 100%;
  display: grid;
  grid-template-columns: 50%50%;
}

.custom-bg {
  background: #f3f7ff80;
}

.collapse-div {
  border-bottom: 1px solid transparent;
  margin-inline: 2rem;
  padding-bottom: 2rem;
}

.collapse-div:hover {
  border-bottom: 1px solid black;
}
