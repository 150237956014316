@import '../../../../../styles/colors.scss';
.div-option-container {
  margin-top: 3rem;
}

.div-types-flex {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 3.5rem;
  row-gap: 1rem;
  align-items: center;
  padding-left: 2rem;
}

.description-title {
  font-family: 'MuseoSansRegular';
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 4%;
  margin: 0;
}
.description-title-p200 {
  color: $primary_200;
}
.description-title-p400 {
  color: $primary_400;
}

.div-container-modal {
  padding-inline: 1.5rem;
  padding-block: 1rem;
}
.div-modal-upload-editable {
  padding-inline: 1.5rem;
  padding-block: 1rem;
  display: grid;
  grid-template-columns: 25% 75%;
  height: 25rem;
}
.div-modal-upload-editable-normal {
  padding-inline: 1.5rem;
  padding-block: 1rem;
  height: 25rem;
}

.container-uploaded-img {
  display: grid;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  row-gap: 0.5rem;
}

.inserver-img-cover {
  width: 100%;
  height: auto;
  border: 2px solid transparent;
}
.inserver-img-cover:hover {
  cursor: pointer;
  border: 2px solid $terciary_200;
  border-radius: 0.5rem;
}
.inserver-img-cover-selected {
  cursor: pointer;
  border: 2px solid $terciary_200;
  border-radius: 0.5rem;
}

.p-modal {
  font-weight: 400;
  font-size: 1rem;
  color: $primary_400;
}

.div-cover-white {
  padding: 1rem;
  background: white;
  border-radius: 1rem;
}

.p-title-text {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  font-family: 'MuseoSansRegular';
}

@mixin button-upload_default($bg) {
  width: 100%;
  height: 1.75rem;
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #d6d6d6;
  background: $bg;
  font-weight: 400;
  font-size: 1rem;
  font-family: 'MuseoSansRegular';
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  position: relative;
}

.pointer {
  cursor: pointer;
}
.pointer-no {
  cursor: not-allowed;
}

.button-upload {
  width: 100%;
  height: 1.75rem;
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #d6d6d6;
  background: transparent;
  font-weight: 400;
  font-size: 1rem;
  font-family: 'MuseoSansRegular';
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  position: relative;
}

.button-upload:hover {
  background: #d6d6d680;
  .arrow-svg {
    stroke: black;
    opacity: 0.45;
  }
}
.button-upload:active {
  background: $terciary_200;
  color: #d6d6d6;
  .arrow-svg {
    stroke: #d6d6d6;
  }
}
.disabled-button-upload {
  width: 100%;
  height: 1.75rem;
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #d6d6d6;
  background: #d9d9d980;
  font-weight: 400;
  font-size: 1rem;
  font-family: 'MuseoSansRegular';
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  position: relative;
}

.cursor-notallowed {
  cursor: not-allowed;
}

.arrow-svg {
  stroke: #d6d6d6;
  width: 1rem;
  height: 1rem;
}

.p-description {
  color: black;
  opacity: 0.45;
  font-size: 0.75rem;
  font-weight: 400;
}

.span-error {
  color: red;
  font-size: 0.7rem;
  margin: 0;
}

.p-color-instruction {
  color: $primary_300;
  padding-left: 0.5rem;
}

.input-pantones {
  width: 100%;
  outline: none;
  background: white;
  border: 1px solid $neutral_50;
  padding-left: 0.75rem;
  border-radius: 2px;
}

.div-container-input {
  margin-top: 0.625rem;
  display: flex;
  column-gap: 1rem;
}

.button-add {
  height: 2.5rem;
}

.label-pantone {
  background: $neutral_25;
  height: 2rem;
  width: 9.25rem;
  border-radius: 0.5rem;
  border: 1px solid $neutral_100;
  padding-inline: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div-color-pantone {
  width: 1rem;
  height: 1rem;
  background-color: red;
  border-radius: 9999px;
}

.p-pantone-added {
  font-family: 'MuseoSansRegular';
  color: $neutral_500;
  font-size: 1rem;
  font-weight: 400;
}

.x-mark-icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.div-wrap-pantones {
  margin-top: 0.625rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 0.625rem;
}

.input-archive-file {
  position: absolute;
  opacity: 0;
}

.notebook-logo {
  width: auto;
  height: auto;
}

.container-notebook-logo {
  height: 100px;
  display: flex;
  justify-content: center;
}

.div-cover-white-portadas {
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  width: 90%;
}

.div-portadas-exteriores-container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
}

.center-div {
  display: flex;
  justify-content: center;
}

.p-select-cover {
  margin: 0;
  padding: 0;
  color: red;
}

.logo-clothe {
  width: auto;
  height: 2rem;
}

.img-logo-clothe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
