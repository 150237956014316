$primary_600: #061D47;
$primary_500: #163670;
$primary_400: #305599;
$primary_300: #537AC1;
$primary_200: #7FA5EA;
$primary_150: #9CBDF9;
$primary_100: #B4CFFF;
$primary_75: #C9DCFF;
$primary_50: #DEEAFF;
$primary_25: #F3F7FF;

$secondary_600: #0A484A;
$secondary_500: #13696C;
$secondary_400: #1F8A8E;
$secondary_300: #2FABB0;
$secondary_200: #41CCD2;
$secondary_150: #56EEF4;
$secondary_100: #7FFAFF;
$secondary_75: #A4FBFF;
$secondary_50: #C9FDFF;
$secondary_25: #EEFEFF;

$terciary_600: #00165C;
$terciary_500: #002085;
$terciary_400: #0029AD;
$terciary_300: #0033D6;
$terciary_200: #0F48FF;
$terciary_150: #3A69FF;
$terciary_100: #658AFF;
$terciary_75: #90ABFF;
$terciary_50: #BCCCFF;
$terciary_25: #E7ECFF;

$extra_600: #450C0E;
$extra_500: #671718;
$extra_400: #892426;
$extra_300: #AB3437;
$extra_200: #CD474A;
$extra_150: #EF5D60;
$extra_100: #FA8486;
$extra_75: #FFA9AB;
$extra_50: #FFCCCD;
$extra_25: #FFEFEF;

$neutral_600: #1A1A1A;
$neutral_500: #505050;
$neutral_400: #616161;
$neutral_300: #797979;
$neutral_200: #8A8A8A;
$neutral_150: #9A9A9A;
$neutral_100: #ADADAD;
$neutral_75: #C2C2C2;
$neutral_55: #D9D9D9;
$neutral_50: #D6D6D6;
$neutral_25: #F5F5F5;

$scrollBar: #4b4b4b;
$scrollBarBack: #b9c8e0;
