@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

.formBook {
  button {
    padding: 15px 55px;
     font-size: 14px;
     font-weight: 600;
  }
  label {
    font-weight: 600;
    font-size: 14px !important;
  }
  .editorialRow {
    width: 1rem;
  }
  .addEditorial {
    height: 20px;
    width: 20px;

    stroke: $terciary_200;
    cursor: pointer;
  }
  .countLetters {
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: flex-end;
    margin-top: -1rem;
    color: rgba(0, 0, 0, 0.25);
    margin-bottom: 0.5rem;
  }


}

.formEditorial{
  padding: 10px 0 0 0;

  .btn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


.bodyEndList{
  height: auto;
  @include center();
  color: $primary_400;
  padding: 24px;
  flex-direction: column;
  background-color: $primary_25;
  text-align: center;
  row-gap: 2rem;
}

.button {
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: flex-end;
}