@import '.././../../styles/colors';

.selectSearchBook {
  width: 20%;
  .ant-select-selector {
    background-color: $neutral_25 !important;
  }
}
.ant-collapse-header {
  padding: 20px !important;
}
