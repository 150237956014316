@import 'styles/colors.scss';
@import 'styles/global.scss';
@import 'styles/responsiveSize.scss';
.backButtonsContainer{
display: flex;
gap: 36px;

  .backButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1.5rem;
    svg {
      height: 12px;
      margin-right: 5px;
    }
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    &:hover{
      text-decoration: underline;
    }
  }
}
