@import '../../../styles/colors.scss';

@mixin absolute-zIndex($z) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: $z;
}

.download-container {
  width: 794px;
  height: 1020px;
  //border: 2px solid red;

  &.selected-1 {
    width: 756px !important;
    height: 983px !important;
  }&.selected-2 {
    width: 794px !important;
    height: 1020px !important;
  }&.selected-3 {
    width: 529px !important;
    height: 794px !important;
  }&.selected-4 {
    width: 926px !important;
    height: 794px !important;
  }&.selected-5 {
    width: 605px !important;
    height: 794px !important;
  }&.selected-6 {
    width: 924px !important;
    height: 605px !important;
  }&.selected-7 {
    width: 737px !important;
    height: 945px !important;
  }&.selected-8 {
    width: 1209px !important;
    height: 907px !important;
  }
}

.download-button-container {
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 12px;
  background-color: rgba(240,241,242,0.8);
  margin-left: auto;
  margin-right: auto;
  z-index: 1000000000000;
  display: flex;
  flex-direction: row-reverse;
}

.div-container-sticky {
  width: 100%;
  height: 100%;
}
.div-notebook-Dragable-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 40.752vw;
  z-index: 30;
}

.dragable-grid {
  position: relative;
  height: 100%;
  width: 100%;
}
.Position-left-draggable-vertical {
  //border: 2px solid red;
  //position: absolute;
  //left: 10.9%;
  //top: 100px;
  height: 100%;
  width: 100%;
}
.pruebaaa {
  border: 2px solid red;
  position: absolute;
  left: 6.6%;
  top: 28.6%;
  height: 54%;
  width: 42.5%;
  z-index: 66669999999999;
}
.Position-left-draggable-horizontal {
  //border: 2px solid red;

  //position: absolute;
  //left: 8.4%;
  //top: 22.9%;
  height: 100%;
  width: 100%;
}
.Position-right-draggable-vertical {
  //border: 2px solid red;
  //position: absolute;
  //left: 56.5%;
  //top: 10.2%;
  height: 100%;
  width: 100%;
}
.Position-right-draggable-horizontal {
  //border: 2px solid red;

  //position: absolute;
  //left: 50.2%;
  //top: 23%;
  height: 100%;
  width: 100%;
}

.div-notebook-svg {
  width: 100%;
  height: 100%;
  //border: 2px solid red;
}
.prueba-grid {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: transparent;
  z-index: 100;
}
.div-dragable-editor-front {
  position: absolute;
  left: 50.8%;
  top: 23.4%;
  width: 42.8%;
  height: 67.3%;
  z-index: 20;
}
//kjhkshkjhskjfdhsdkjfhskjhfkjsdhfksdjhfkjsdfhkjsdhfkjsdhjfkhsdkjfhsdkjfh
.div-dragable-editor-back {
  position: absolute;
  left: 6.5%;
  top: 23.4%;
  width: 42.8%;
  height: 67.3%;
  z-index: 20;
}
.prueba-2-relative {
  position: relative;
  border: 1px black solid;
  z-index: 99999;
}
.div-notebook-svg-sticky {
  width: 100%;
  height: 40.752vw;
  position: sticky;
  top: 5rem;
  display: grid;
  grid-template-columns: 50%50%;
}

.div-notebook-svg-sticky-preview {
  width: 80%;
  height: 80%;
  margin: auto;
  margin-top: 8rem;
}

.div-polo-notebook-relative {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  border: 3px solid yellow;

}
.div-polo-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(5);
}

.svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  //border: 3px solid red;
}
.svg-logo {
  width: 100%;
  height: auto;
}

.div-shadows-svg-absolute {
  width: 100%;
  height: 100%;

  @include absolute-zIndex(6);
}
.div-label-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(2);
}
.div-tags-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(8);
}

.ppp {
  width: 100%;
  height: 100%;
  //@include absolute-zIndex(1);
}

.change-index {
  overflow: hidden;
  z-index: 19;
}
.change-index-low {
  overflow: hidden;
  z-index: 11;
}

.div-sujecion-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(10);
}

.font-polo {
  font-size: 3.22px;
}

@mixin center_img_hori() {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.3%;
  height: 3.3%;
  border-radius: 9999px;
  z-index: 10;
  top: 33.7%;
}

.logo-position-hori-left {
  @include center_img_hori();
  position: absolute;
  left: 6.5%;
}
.logo-position-hori-right {
  @include center_img_hori();
  position: absolute;
  right: 16.3%;
}
.logo-position-hori-extremos {
  @include center_img_hori();
  position: absolute;
  right: 16.3%;
}
@mixin center_img_ver() {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.3%;
  height: 4%;
  border-radius: 9999px;
  z-index: 10;
  top: 25.6%;
}

.logo-position-ver-left {
  @include center_img_ver();
  position: absolute;
  left: 7.7%;
}
.logo-position-ver-right {
  @include center_img_ver();
  position: absolute;
  right: 22%;
}
.logo-position-ver-extremos {
  @include center_img_ver();
  position: absolute;
  right: 22%;
}

.date1-font-12 {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.date1-blue {
  fill: #003da6;
}

.transform-invert {
  //transform: scaleX(-1);
}

.div-upload-container-relative {
  position: relative;
  width: 100%;
  height: 100%;
  //border: 2px solid green;
}

.div-img-upload {
  //top: 10%;
  //left: 12.8%;
  position: absolute;
  width: 100%;
  height: 100%;
}
.div-img-upload-inverter {
  //top: 10.2%;
  //left: 21.8%;
  position: absolute;
  width: 100%;
  height: 100%;
}
.div-img-upload-inverter-horizontal {
  //top: 33.4%;
  //left: 16.5%;
  //position: absolute;
  width: 100%;
  height: 100%;
}

.div-img-upload-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.div-img-upload-container-width {
  width: 400%;
    border: 2px solid green;

}

.div-img-container-22 {
  position: absolute;
  width: 100%;
  top: 0;
  right: none;
}
.div-img-container-33 {
  position: absolute;
  height: 100%;
  left: 0;
  right: none;
}

.number-opacity {
  opacity: 0.5;
  cursor: grab;
}

.overflow-hidden {
  overflow: hidden;
}

.border-right-rounded {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.border-left-rounded {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.border-right-rounded-hori {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.border-left-rounded-hori {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.div-img-upload-2 {
  top: 18.8%;
  left: 1.5%;
  position: absolute;
  width: 85.5%;
  height: 66.6%;
  overflow: hidden;
}

.div-img-upload-3 {
  top: 10.2%;
  left: 18.7%;
  position: absolute;
  width: 55%;
  height: 85%;
  overflow: hidden;
}

.div-img-upload-4 {
  top: 24.5%;
  left: 4%;
  position: absolute;
  width: 73.8%;
  height: 66.4%;
  overflow: hidden;
}

.div-img-upload-horizontal {
  //top: 33.3%;
  //left: 0%;
  //position: absolute;
  width: 100%;
  height: 100%;
}

.img-upload {
  height: 100%;
  width: 100%;
}

.rounded-borders {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.rounded-borders-horizont {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.rounded-borders-left {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.rounded-borders-left-horizon {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.img-logo-dragable {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-div-container-drag {
  border: 1px solid purple;
  width: 100%;
  height: 100%;
  position: relative;
}

//editable panel
.container-editable-panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding-left: 3rem;
  padding-top: 1rem;
  display: flex;
  column-gap: 2rem;
}
.button-action {
  width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 0.5rem;
  border: 2px solid transparent;
}
.button-action:hover {
  background: #d9d9d9;
  border: 2px solid $terciary_200;
  color: $terciary_200;
  .icon-button-action {
    stroke: $terciary_200;
  }
}
.button-action:active {
  background: $terciary_200;
  color: white;
  border: 2px solid $terciary_200;
  .icon-button-action {
    stroke: white;
  }
}
.icon-button-action {
  width: 25px;
  height: 25px;
}
.flex-container-buttons {
  display: flex;
  column-gap: 1rem;
  background: #d9d9d9;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
//editable panel

.buttons-select {
  padding: 0;
  margin: 0;
}

.son-custom {
  margin-top: 1rem;
  display: flex;
  column-gap: 1rem;
}

.border-selected {
  border: 2px solid transparent;
  border-radius: 0.5rem;
}
.border-color {
  border-color: $terciary_200;
}

.cover-on-edith {
  border: 2px solid transparent;
  border-radius: 0.7rem;
}

.cover-on-edith-color {
  border-color: red;
}
