@import '../../../styles/colors.scss';
.upload-input-component {
  display: flex;
  flex-direction: column;
  label {
    color: $primary_600 !important;
    font-size: 16px;
    line-height: 18px;
  }
  .input-content {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
  }
  .ant-upload.ant-upload-select{
    width: 100%;
  }
  button{
    width: 100%;
    height: 40px;
  }
  span.ant-upload-list-item-name{
    color: $terciary_100
  }
}
