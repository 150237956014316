@import '../../styles/colors.scss';
@import '../../styles/global.scss';
.containerCollapsible {
  .collapsible {
    border-bottom: 1px solid $primary_300;
    color: $primary_400;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: $primary_75;
    }
  }
  .defaultBg {
    background-color: $primary_25;
  }
  .collapseTitle {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    .chevron {
      width: 20px;
      height: 20px;
      transition: all 0.2s ease-out;
    }
  }
  .sideElement{
    width: 40%;
  }
  .collapsibleContent {
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: white;
  }
  .showCollapse {
    max-height: 100%;
  }
  .hideCollapse {
    max-height: 0;
  }

  .rotate-180 {
    transform: rotate(180deg);
  }
}
