@import '../../../styles/colors.scss';

.body-div {
  padding-top: 3.5rem;
  padding-left: 3rem;
}

.div-img {
  width: 100%;
  height: 15.9rem;
}
.img-notebook {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-notebook-italiano {
  width: 100%;
  height: auto;
}

.center {
  display: flex;
  align-items: center;
}
.div-container {
  width: 13.5rem;
  cursor: pointer;
}

.div-container-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.p-name-notebook {
  font-family: 'MuseoSansRegular';
  font-weight: 400;
  font-size: 1rem;
  color: $primary_400;
  padding-inline: 0.5rem;
}

.p-instruction {
  font-family: 'MuseoSansRegular';
  font-weight: 600;
  font-size: 1rem;
  color: $primary_600;
}

.rotate {
  transform: rotate(90deg);
}

.selected-notebook {
  border: 2px solid #0f48ff;
  border-radius: 0.5rem;
}
