.booksTable {
  tr {
    cursor: pointer;
  }
}

.label-check {
  user-select: none;
  display: flex;
  align-items: center;
}

.p-products {
  margin: 0;
  padding: 0;
  display: flex;
}

.icon-svg {
  width: 1rem;
  height: 1rem;
  stroke: green;
}
