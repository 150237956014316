@import '../../../styles/colors.scss';
.input-component {
  display: flex;
  flex-direction: column;
 label{
  color: $primary_600!important;
font-size: 16px;
line-height: 18px;
 }
  .input-content {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
  }
  input.custom {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    font-family: 'MuseoSansLight';
    padding: 12px;
    height: 40px;
    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }
    &:focus-visible {
      border: 1px solid $neutral_600;
      outline: none;
    }
    &:disabled {
      background-color: $neutral_25 !important;
      border: 1px solid $neutral_50 !important;
      &::placeholder {
        color: $neutral_200 !important;
      }
    }
    &[data-variation='error'] {
      color: $extra_500;
      border: 1px solid $extra_600;
      background-color: #ffeef0;
      &::placeholder {
        color: $extra_500;
      }
    }
  }
}
