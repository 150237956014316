@import '../../../styles/colors.scss';

.button {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.labelPrice{
  color: $terciary_200
}
