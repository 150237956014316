@import './colors.scss';

//Alkatra
@font-face {
  font-family: 'AlkatraBold';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Alkatra-Bold.ttf') format('OpenType');
  font-weight: 700;
}
@font-face {
  font-family: 'AlkatraMedium';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Alkatra-Medium.ttf') format('OpenType');
  font-weight: 500;
}
@font-face {
  font-family: 'AlkatraRegular';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Alkatra-Regular.ttf') format('OpenType');
  font-weight: 400;
}
@font-face {
  font-family: 'AlkatraSemibold';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Alkatra-SemiBold.ttf') format('OpenType');
  font-weight: 600;
}
@font-face {
  font-family: 'AlkatraVariable';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Alkatra-VariableFont_wght.ttf')
      format('OpenType');
}

//Alkatra
//Roboto
@font-face {
  font-family: 'Roboto-Thin';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Roboto-Thin.ttf') format('OpenType');
}
@font-face {
  font-family: 'Roboto-Light';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Roboto-Light.ttf') format('OpenType');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Roboto-Regular.ttf') format('OpenType');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Roboto-Medium.ttf') format('OpenType');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/Roboto-Bold.ttf') format('OpenType');
}
//Roboto
//Open sans
@font-face {
  font-family: 'OpenSans-Light';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/OpenSans-Light.ttf') format('OpenType');
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/OpenSans-Regular.ttf') format('OpenType');
}
@font-face {
  font-family: 'OpenSans-Medium';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/OpenSans-Medium.ttf') format('OpenType');
}
@font-face {
  font-family: 'OpenSans-SemiBold';

  src: local('Alkatra'),
    url('../assets/fonts-wordArt/OpenSans-SemiBold.ttf') format('OpenType');
}
@font-face {
  font-family: 'OpenSans-Bold';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/OpenSans-Bold.ttf') format('OpenType');
}
//Open sans
//Concer one
@font-face {
  font-family: 'Concert-One';
  src: local('Alkatra'),
    url('../assets/fonts-wordArt/ConcertOne-Regular.ttf') format('OpenType');
}
//Concer one
