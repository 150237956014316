@import '../../styles/colors.scss';
.size-icons {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
.edith-color {
  stroke: $terciary_200;
}

.trash-color {
  stroke: $extra_150;
}

.p-text {
  margin: 0;
  padding: 0;
}

.input-field {
  width: 2.5rem;
  border: 2px #d9d9d9 solid;
  border-radius: 0.3rem;
}

.input-field::-webkit-outer-spin-button,
.input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-field[type='number'] {
  -moz-appearance: textfield;
}

.input-field:focus {
  border-color: blue;
  outline: none;
}
