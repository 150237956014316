@import '../../styles/global.scss';
@import '../../styles/colors.scss';


.filters{
   width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 2rem;
  margin-bottom: 1.8rem;
  div{
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
   
    article{
      color: #32569A!important;
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.inputSearch{
  margin-bottom: 0rem;
  button{
    background-color: $terciary_200!important;
  }
}

.inputSearchGral{
  margin-bottom: 2rem;
  button{
    background-color: $terciary_200!important;
  }
}

.searchContainer {
  height: auto;
  @include center();
  color: $primary_400;
  padding: 0px 24px 10px 24px;
  flex-direction: column;
  background-color: $primary_25;
  .inputSearch {
    width: 100%;
    margin-top: 2rem;

    article {
      color: $primary_400;
      font-weight: 400;
    }
  }
 
}

.booksTable {
  tr {
    cursor: pointer;
  }
}

.footerContent {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: flex-end;
}

.collapsePanel{
  background-color: $primary_25!important;
}

.endList{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $primary_25;
  margin-bottom: 1rem;
  padding: 1rem;
  border-top: 2px solid $primary_300;
}

.bodyEndList{
  height: auto;
  @include center();
  color: $primary_400;
  padding: 24px;
  flex-direction: column;
  background-color: $primary_25;
  text-align: center;
  row-gap: 2rem;
}