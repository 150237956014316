.modal-in-upload-img-uniforms .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
  padding: 20px 0px;
}
.modal-in-upload-img-uniforms .ant-modal-content .ant-modal-header {
  color: rgba(0, 0, 0, 0.88);
  background: #ffffff;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
  padding-inline: 1.5rem;
}
.modal-in-upload-img-uniforms .ant-modal-content .ant-modal-footer {
  text-align: end;
  background: transparent;
  margin-top: 12px;
  padding-inline: 1.5rem;
}
