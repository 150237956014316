@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

.notebook-icon {
  stroke: $terciary_200;
  width: 1rem;
  height: 1rem;
}
.trash-icon {
  stroke: red;
  width: 1rem;
  height: 1rem;
}

.plus-circle-icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.modal-input {
  width: 100%;
  height: 2.5rem;
  padding-left: 0.75rem;
  font-family: 'MuseoSansRegular';
  font-size: 1rem;
  border: 1px solid $neutral_50;
  border-radius: 2px;
  outline: none;
}

.footer-style {
  display: flex;
  justify-content: end;
  column-gap: 0.5rem;
  padding-top: 0.625rem;
}

.label-modal {
  font-family: 'MuseoSansRegular';
  color: $primary_600;
  font-weight: 600;
  font-size: 1rem;
}

.div-container {
  padding: 1rem;
}

.list-item-style {
  padding: 10px 0;
}
.list-item-style:hover {
  background: $neutral_50;
  cursor: pointer;
}

.bodyEndList{
  height: auto;
  @include center();
  color: $primary_400;
  padding: 24px;
  flex-direction: column;
  background-color: $primary_25;
  text-align: center;
  row-gap: 2rem;
}