@import '../../../../../styles/colors.scss';

@mixin absolute-zIndex($z) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: $z;
}

.div-polo-svg-sticky {
  width: 100%;
  height: 40.752vw;
  position: sticky;
  top: 5rem;
}
.div-polo-svg-relative {
  width: 100%;
  height: 100%;
  position: relative;
}
.div-polo-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(5);
}

.svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.svg-logo {
  width: 100%;
  height: auto;
}

.div-shadows-svg-absolute {
  width: 100%;
  height: 100%;

  @include absolute-zIndex(6);
}
.div-label-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(2);
}
.div-tags-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(8);
}

.font-polo {
  font-size: 3.22px;
}

@mixin center_img($w, $h, $t) {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid transparent;
  width: $w;
  height: $h;
  border-radius: 9999px;
  z-index: 10;
  top: $t;
}

.logo-position-left {
  @include center_img(6.3%, 7.6%, 30.5%);
  position: absolute;
  right: 34.8%;
}
.logo-position-right {
  @include center_img(6.3%, 7.6%, 30.5%);
  position: absolute;
  left: 36.5%;
}
.logo-position-fema-left {
  @include center_img(6.3%, 7.6%, 30.5%);
  position: absolute;
  right: 33.8%;
}
.logo-position-fema-right {
  @include center_img(6.3%, 7.6%, 30.5%);
  position: absolute;
  left: 35.5%;
}
.logo-position-back {
  @include center_img(6.3%, 7.6%, 30.5%);
  display: none;
}
.logo-position-center {
  @include center_img(6.3%, 7.6%, 30.5%);
  display: none;
}
//---------------------------------------------chaleco capitonado

.logo-position-chaleco-capitonado-left {
  @include center_img(4%, 5.3%, 31.8%);
  position: absolute;
  left: 56.7%;
  border: 1px solid;
}
.logo-position-chaleco-capitonado-right {
  @include center_img(4%, 5.3%, 31.8%);
  position: absolute;
  left: 38.2%;
  border: 1px solid;
}
//---------------------------------------------chamarra capitonado

.logo-position-chamarra-capitonado-left {
  @include center_img(4.2%, 5.5%, 30.1%);
  position: absolute;
  left: 57.8%;
  border: 1px solid;
}
.logo-position-chamarra-capitonado-right {
  @include center_img(4.2%, 5.5%, 30.1%);
  position: absolute;
  left: 36.6%;
  border: 1px solid;
}
.logo-position-chamarra-capitonado-m-right {
  @include center_img(4.2%, 5.5%, 33.2%);
  position: absolute;
  left: 21.1%;
  border: 1px solid;
}
.logo-position-chamarra-capitonado-m-left {
  @include center_img(4.2%, 5.5%, 33.2%);
  position: absolute;
  left: 74.2%;
  border: 1px solid;
}
//---------------------------------------------chaqueta

.logo-position-chaqueta-left {
  @include center_img(5.9%, 7.2%, 28.3%);
  position: absolute;
  left: 56.8%;
  border: 1px solid;
}
.logo-position-chaqueta-right {
  @include center_img(5.9%, 7.2%, 28.3%);
  position: absolute;
  left: 37.8%;
  border: 1px solid;
}
//---------------------------------------------pants

.logo-position-pants-left {
  @include center_img(2.9%, 4.2%, 24.5%);
  position: absolute;
  left: 56.6%;
  border: 1px solid;
}
.logo-position-pants-right {
  @include center_img(2.9%, 4.2%, 24.5%);
  position: absolute;
  left: 38.8%;
  border: 1px solid;
}
//---------------------------------------------falda short

.logo-position-faldashort-left {
  @include center_img(2.9%, 4.2%, 60.1%);
  position: absolute;
  left: 57.5%;
  border: 1px solid;
}
.logo-position-faldashort-right {
  @include center_img(2.9%, 4.2%, 60.1%);
  position: absolute;
  left: 39.8%;
  border: 1px solid;
}
//--------------------------------------------- short deportivo

.logo-position-shortdepo-left {
  @include center_img(3.7%, 5%, 58.5%);
  position: absolute;
  left: 62.2%;
  border: 1px solid;
}
.logo-position-shortdepo-right {
  @include center_img(3.7%, 5%, 58.5%);
  position: absolute;
  left: 32.9%;
  border: 1px solid;
}
//--------------------------------------------- camisa deportivo male

.logo-position-shirtdepo-left {
  @include center_img(6.3%, 7.7%, 26.8%);
  position: absolute;
  left: 59.7%;
  border: 1px solid;
}
.logo-position-shirtdepo-right {
  @include center_img(6.3%, 7.7%, 26.8%);
  position: absolute;
  left: 35.6%;
  border: 1px solid;
}
//--------------------------------------------- camisa deportivo female

.logo-position-shirtdepo-fema-left {
  @include center_img(6.3%, 7.7%, 27.8%);
  position: absolute;
  left: 59.7%;
  border: 1px solid;
}
.logo-position-shirtdepo-fema-right {
  @include center_img(6.3%, 7.7%, 27.8%);
  position: absolute;
  left: 35.6%;
  border: 1px solid;
}
//--------------------------------------------- t shirt

.logo-position-TShirt-left {
  @include center_img(6.3%, 7.7%, 27%);
  position: absolute;
  left: 59.8%;
  border: 1px solid;
}
.logo-position-TShirt-right {
  @include center_img(6.3%, 7.7%, 27%);
  position: absolute;
  left: 35.6%;
  border: 1px solid;
}
//--------------------------------------------- t shirt female

.logo-position-TShirt-fema-left {
  @include center_img(6.3%, 7.7%, 27.9%);
  position: absolute;
  left: 59.8%;
  border: 1px solid;
}
.logo-position-TShirt-fema-right {
  @include center_img(6.3%, 7.7%, 27.9%);
  position: absolute;
  left: 35.6%;
  border: 1px solid;
}
//--------------------------------------------- t shirt female

.logo-position-3_4-left {
  @include center_img(5.2%, 6.6%, 25.9%);
  position: absolute;
  left: 59.4%;
  border: 1px solid;
}
.logo-position-3_4-right {
  @include center_img(5.2%, 6.6%, 25.9%);
  position: absolute;
  left: 35.5%;
  border: 1px solid;
}
.logo-position-3_4-m-right {
  @include center_img(5.2%, 6.6%, 25.9%);
  position: absolute;
  left: 21.3%;
  border: 1px solid;
}
.logo-position-3_4-m-left {
  @include center_img(5.2%, 6.6%, 25.9%);
  position: absolute;
  left: 73.5%;
  border: 1px solid;
}
//--------------------------------------------- jumper

.logo-position-jumper-left {
  @include center_img(3.2%, 4.6%, 21.1%);
  position: absolute;
  left: 57.9%;
  border: 1px solid;
}
.logo-position-jumper-right {
  @include center_img(3.2%, 4.6%, 21.1%);
  position: absolute;
  left: 41.7%;
  border: 1px solid;
}
.logo-position-jumper-f-right {
  @include center_img(3.2%, 4.6%, 83%);
  position: absolute;
  left: 31.3%;
  border: 1px solid;
}
.logo-position-jumper-f-left {
  @include center_img(3.2%, 4.6%, 83.8%);
  position: absolute;
  left: 64.8%;
  border: 1px solid;
}
//--------------------------------------------- falda

.logo-position-falda-s-left {
  @include center_img(3.4%, 4.8%, 68.5%);
  position: absolute;
  left: 65.4%;
  border: 1px solid;
}
.logo-position-falda-s-right {
  @include center_img(3.4%, 4.8%, 68.5%);
  position: absolute;
  left: 32.3%;
  border: 1px solid;
}
.logo-position-falda-i-right {
  @include center_img(3.2%, 4.6%, 69%);
  position: absolute;
  left: 31.1%;
  border: 1px solid;
}
.logo-position-falda-i-left {
  @include center_img(3.2%, 4.6%, 69%);
  position: absolute;
  left: 66%;
  border: 1px solid;
}
//--------------------------------------------- sueter

.logo-position-sueter-left {
  @include center_img(5.4%, 6.8%, 30%);
  position: absolute;
  left: 59%;
  border: 1px solid;
}
.logo-position-sueter-right {
  @include center_img(5.4%, 6.8%, 30%);
  position: absolute;
  left: 35.5%;
  border: 1px solid;
}

.logo-position-sueter-fem-left {
  @include center_img(3.4%, 4.8%, 37.8%);
  position: absolute;
  left: 59.8%;
  border: 1px solid;
}
.logo-position-sueter-fem-right {
  @include center_img(3.4%, 4.8%, 37.8%);
  position: absolute;
  left: 37.5%;
  border: 1px solid;
}
//--------------------------------------------- camisa manga larga manga corta

.logo-position-MlMc-m-left {
  @include center_img(5.4%, 6.8%, 36.6%);
  position: absolute;
  left: 76.3%;
  border: 1px solid;
}
.logo-position-MlMc-m-right {
  @include center_img(5.4%, 6.8%, 36.6%);
  position: absolute;
  left: 17.6%;
  border: 1px solid;
}

.logo-position-MlMc-bolsa {
  @include center_img(5.4%, 6.8%, 37%);
  position: absolute;
  left: 56.8%;
  border: 1px solid;
}
//--------------------------------------------- chaleco normal

.logo-position-saco-left {
  @include center_img(4.6%, 6%, 35.3%);
  position: absolute;
  left: 57.7%;
  border: 1px solid;
}
.logo-position-saco-right {
  @include center_img(4.6%, 6%, 35.3%);
  position: absolute;
  left: 36.2%;
  border: 1px solid;
}

//--------------------------------------------- pantalon

.logo-position-pantalon-left {
  @include center_img(2.6%, 4%, 24.1%);
  position: absolute;
  left: 59.3%;
  border: 1px solid;
}
.logo-position-pantalon-right {
  @include center_img(2.6%, 4%, 24.1%);
  position: absolute;
  left: 38.4%;
  border: 1px solid;
}
//--------------------------------------------- saco gala

.logo-position-sacosaco-left {
  @include center_img(4.6%, 6%, 40.9%);
  position: absolute;
  left: 60.8%;
  border: 1px solid;
}
.logo-position-sacosaco-right {
  @include center_img(4.6%, 6%, 40.9%);
  position: absolute;
  left: 33.4%;
  border: 1px solid;
}
//--------------------------------------------- hoodie

.logo-position-hoodie-p-left {
  @include center_img(3.6%, 5%, 43.5%);
  position: absolute;
  left: 55.8%;
  border: 1px solid;
}
.logo-position-hoodie-p-right {
  @include center_img(3.6%, 5%, 43.5%);
  position: absolute;
  left: 40.2%;
  border: 1px solid;
}
.logo-position-hoodie-m-left {
  @include center_img(3.6%, 5%, 43.5%);
  position: absolute;
  left: 65.2%;
  border: 1px solid;
}
.logo-position-hoodie-m-right {
  @include center_img(3.6%, 5%, 43.5%);
  position: absolute;
  left: 29.7%;
  border: 1px solid;
}
.logo-position-hoodie-centro {
  @include center_img(14.6%, 16%, 39.5%);
  position: absolute;
  left: 42.5%;
  border: 1px solid;
}
.logo-position-hoodie-espalda {
  @include center_img(13.6%, 15%, 40.9%);
  position: absolute;
  left: 43%;
  border: 1px solid;
}
//--------------------------------------------- hoodie

.logo-position-corbata-top {
  @include center_img(1.8%, 3.2%, 15.5%);
  position: absolute;
  left: 49.2%;
  border: 1px solid;
}
.logo-position-corbata-center {
  @include center_img(2%, 3.4%, 64.6%);
  position: absolute;
  left: 49.6%;
  border: 1px solid;
}
//--------------------------------------------- traje de baño masculino

.logo-position-banoMale-s-left {
  @include center_img(5%, 6.4%, 31.8%);
  position: absolute;
  left: 60.1%;
  border: 1px solid;
}
.logo-position-banoMale-s-right {
  @include center_img(5%, 6.4%, 31.8%);
  position: absolute;
  left: 36.2%;
  border: 1px solid;
}
.logo-position-banoMale-i-left {
  @include center_img(5%, 6.4%, 68.9%);
  position: absolute;
  left: 63.1%;
  border: 1px solid;
}
.logo-position-banoMale-i-right {
  @include center_img(5%, 6.4%, 67.6%);
  position: absolute;
  left: 32.5%;
  border: 1px solid;
}
//--------------------------------------------- traje de baño femenino

.logo-position-banoFema2-left {
  @include center_img(3.5%, 4.9%, 31.5%);
  position: absolute;
  left: 55.3%;
  border: 1px solid;
}
.logo-position-banoFema2-center {
  @include center_img(3.5%, 4.9%, 31.5%);
  position: absolute;
  left: 47.9%;
  border: 1px solid;
}
.logo-position-banoFema2-right {
  @include center_img(3.5%, 4.9%, 31.5%);
  position: absolute;
  left: 40.4%;
  border: 1px solid;
}
.logo-position-banoFema2-i-right {
  @include center_img(1.8%, 2.5%, 69.5%);
  position: absolute;
  left: 38.4%;
  border: 1px solid;
}
.logo-position-banoFema2-i-left {
  @include center_img(1.8%, 2.5%, 70.2%);
  position: absolute;
  left: 58.2%;
  border: 1px solid;
}
//--------------------------------------------- traje de baño femenino

.logo-position-banoFema-left {
  @include center_img(3.5%, 4.9%, 29.9%);
  position: absolute;
  left: 54.3%;
  border: 1px solid;
}
.logo-position-banoFema-center {
  @include center_img(5.8%, 7.5%, 29.9%);
  position: absolute;
  left: 46.9%;
  border: 1px solid;
}
.logo-position-banoFema-right {
  @include center_img(3.5%, 4.9%, 29.9%);
  position: absolute;
  left: 41.7%;
  border: 1px solid;
}
