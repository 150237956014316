@mixin flex-base {
  display: flex;
  align-items: center;
}

.body-div {
  padding-inline: 3rem;
  padding-top: 4.5rem;
  padding-bottom: 2rem;
}

.uniform-icon {
  width: 2rem;
  height: 2rem;
}

.div-Title {
  @include flex-base;
  grid-column-gap: 1rem;
  justify-content: space-between;
}

.P-title {
  font-weight: 600;
  font-size: 2rem;
  cursor: text;
  margin: 0;
  color: #305599;
}

.div-cards {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}
