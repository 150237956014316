@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';

.inputDate {
  & > div {
    height: 29.30px;
    padding: 0;
  }
  input {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18px;
    font-family:'MuseoSansLight' ;
    height: 40px;
  }
}
