@import './../../styles/colors';

.formLogin {
  margin-top: 3rem;

  .btn {
    background-color: $extra_150 !important;
    margin-top: 0.5rem;
    color: #fff;
    padding: 0 6rem;
  }
}

.btnForgot {
  margin-top: 0.3rem;
  color: $terciary_200 !important;
  font-weight: bolder;
}
