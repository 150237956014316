@import '../../styles/colors.scss';

@mixin flex-base {
  display: flex;
  align-items: center;
}
.gradeTitle,
.container-Title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  margin-bottom: 38px;
  gap: 20px;
  button {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding: 15px 20px;
  }
  .buttonContainer {
    display: flex;
    gap: 32px;
  }
}

.gradeTitle {
  color: $primary_400;
  .gradeInfo {
    display: flex;
    gap: 46px;
    .Grade {
      display: flex;
      gap: 14px;
    }
  }
}
.P-title {
  font-weight: 600;
  font-size: 2rem;
  cursor: text;
  margin: 0;
  color: $primary_400;
}

.div-Title {
  @include flex-base;
  grid-column-gap: 1rem;
  justify-content: space-between;
  color: $primary_400;
}
