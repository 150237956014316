@import '../../../styles/colors.scss';
@import '../../../styles/global.scss';
.formBookComments {
  margin:1rem 0 1rem 0;
 
  label {
    font-weight: 600;
    font-size: 14px !important;
  }
  



  .btn{
    display: flex;
    align-items: center;
    button{
      width: 100%;
      @include center;
      font-size: 14px;
      font-weight: 600;
    }
  }
}


.bodyEndList{
  height: auto;
  @include center();
  color: $primary_400;
  padding: 24px;
  flex-direction: column;
  background-color: $primary_25;
  text-align: center;
  row-gap: 2rem;
}

.button {
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: flex-end;
}