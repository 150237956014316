@import '../../styles/colors.scss';
.plus-circle-icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.collapse-padding {
  margin-bottom: 1rem;
  border: 1px solid black;
  height: 100%;
}

.notebook-icon {
  stroke: $terciary_200;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
.trash-icon {
  stroke: red;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.descriptionstyle {
  width: 100%;
  display: grid;
  grid-template-columns: 50%50%;
}

.custom-bg {
  background: #f3f7ff80;
}

.padding-inline {
  padding-inline: 0.5rem;
}

.spin-center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.footer-bar {
  border-top: 2px solid $primary_300;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 1rem;
  background: $primary_25;
  height: 3.8rem;
  align-items: center;
}
.footer-bar-2 {
  border-top: 2px solid $primary_300;
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  background: $primary_25;
  height: 3.8rem;
  align-items: center;
}

.p-total {
  margin: 0;
  padding: 0;
  padding-left: 2rem;
  font-size: 1.2rem;
}

.span-color {
  color: red;
}

.status-pacgake {
  margin: 0;
  padding: 0;
  padding-inline: 0.5rem;
  border-radius: 999px;
  border: 1px solid orange;
  color: white;
  background-color: orange;

  &.finished {
    border: 1px solid #00d355;
    background-color: #00d355;
  }
}

.table-size {
  width: 100%;
}
