.bg {
  height: 100vh;
  background: url('../../assets/img/bgLogin.svg');
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: #FFF;
    padding: 48px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
