@import '../../../../styles/colors.scss';

.div-select-all {
  background: $primary_25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.125rem;
  padding-inline: 1rem;
}

.p-level-title {
  margin: 0;
  font-size: 1rem;
  font-family: 'MuseoSansRegular';
  font-weight: 600;
}

.hr-line {
  border: 0;
  border-top: 1px solid $neutral_50;
  margin-top: 1.563rem;
  margin-bottom: 1.563rem;
}

.center-label {
  width: fit-content;
  column-gap: 0.5rem;
  display: flex;
  align-items: center;
}

.div-wrap-label {
  padding-left: 1rem;
  display: grid;
  grid-template-columns: 33%33%33%;
  margin-top: 1.563rem;
  row-gap: 1.563rem;
}
