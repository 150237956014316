@import '../../../../../styles/colors.scss';
.footer-style {
  display: flex;
  justify-content: end;
  column-gap: 0.5rem;
  padding-top: 0.625rem;
}

.div-container {
  padding: 1rem;
}

.label-modal {
  font-family: 'MuseoSansRegular';
  color: $primary_600;
  font-weight: 600;
  font-size: 1rem;
}

.modal-input {
  width: 100%;
  height: 2.5rem;
  padding-left: 0.75rem;
  font-family: 'MuseoSansRegular';
  font-size: 1rem;
  border: 1px solid $neutral_50;
  border-radius: 2px;
  outline: none;
}

.div-warning {
  background: $primary_25;
  padding-inline: 1.5rem;
  padding-block: 1rem;
}

.div-center-warning {
  display: flex;
  justify-content: center;
}
.exclamation-triangle {
  stroke: $extra_150;
  width: 6.875rem;
  height: 6.875rem;
}
.label-modal {
  font-size: 1rem;
  font-weight: 400;
  color: $primary_400;
  font-family: 'MuseoSansRegular';
}

.label-error {
  color: red;
  font-family: 'MuseoSansRegular';
}
.border-red {
  border-color: red;
}

.margin-top {
  margin-top: -1rem;
}

.div-options {
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  overflow: hidden;
}

.p-option-list {
  padding: 0;
  margin: 0;
  padding-left: 1rem;
}
.p-option-list:hover {
  background: #d9d9d9;
  cursor: pointer;
}

.div-container-price {
  width: 100%;
}

.div-border {
  border: 1px solid #d9d9d9;
  height: 2.5rem;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  cursor: pointer;
}
