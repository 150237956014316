@import '../../../../styles/colors.scss';
.bar-fixed {
  height: 5rem;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 15;
  padding-inline: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -4px 16px rgba(22, 54, 112, 0.08);
}

.div-flex {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  width: fit-content;
  background: $primary_25;
  height: 3.5rem;
  border-radius: 999px;
  padding-left: 1.688rem;
}

.p-margin {
  margin: 0;
}
.div-price-container {
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price-title {
  font-weight: 600;
  font-size: 0.625rem;
  color: $primary_300;
  font-family: 'MuseoSansRegular';
}

.price-numbers {
  font-weight: 600;
  font-size: 1.25rem;
  color: $primary_400;
  font-family: 'MuseoSansRegular';
  padding-right: 2rem;
}

.p-title {
  font-size: 1rem;
  width: 9.563rem;
  font-weight: 600;
  font-family: 'MuseoSansRegular';
  color: $primary_600;
  margin: 0;
}

.uniform-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.red-button {
  width: 15rem;
  height: 3rem;
}

.div-container-buttons {
  display: flex;
  column-gap: 1rem;
  width: 100%;
  justify-content: flex-end;

  &.in-status-8 {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}

.hero-chevron {
  width: 1.5rem;
  height: 1.5rem;
  stroke: $primary_100;
}

.span-icon {
  font-weight: 600;
  font-family: 'MuseoSansRegular';
  height: 2rem;
  width: 2rem;
  border-radius: 999px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.span-green {
  background: #4bb543;
}
.span-blue {
  background: $terciary_200;
  color: #ffffff;
}
.span-stay {
  background: transparent;
  border: 1px solid $primary_200;
  color: $primary_200;
}

.p-green {
  color: #4bb543;
}
.p-blue {
  color: $primary_600;
}
.p-grey {
  color: $primary_200;
}

.check-icon {
  width: 1rem;
  height: 1rem;
  stroke: white;
}

.div-option-status {
  display: flex;
  align-items: center;
}
