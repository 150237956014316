.S {
  margin: 0;
  color: currentColor;
  &[data-variation='1'] {
    font-size: 24px;
    line-height: 24px;
  }
  &[data-variation='2'] {
    font-size: 20px;
    line-height: 24px;
  }
  &[data-size='Bold'] {
    font-family: 'MuseoSansBold';
  }
  &[data-size='Light'] {
    font-family: 'MuseoSansLight';
  }
}
