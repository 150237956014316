@import '../../../styles/global.scss';
@import '../../../styles/colors.scss';

.schoolList {
  .schoolAdminTable {
    tr {
      cursor: pointer;
    }
  }
}
