.Overline {
  margin: 0;
  color: currentColor;
  &[data-variation='1'] {
    font-size: 10px;
    line-height: 12px;
  }
  &[data-size='Bold'] {
    font-family: 'MuseoSansBold';
  }
  &[data-size='Light'] {
    font-family: 'MuseoSansLight';
  }
}
