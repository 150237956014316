.div-card-container {
  width: 13.75rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 16px rgba(22, 54, 112, 0.24);
  cursor: pointer;
  border: 2px solid transparent;
}

.border-selected {
  border-color: #1890ff;
}

.div-img {
  height: 16.5rem;
}

.name-type {
  height: 3.5rem;
  background: #00165c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
  }

.p-name {
  margin: 0;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 1.25rem;
}

.img-uniform {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
