@import 'styles/colors.scss';
@import 'styles/global.scss';
@import 'styles/responsiveSize.scss';

.sidebar {
  display: flex;
  flex-direction: column;
  width: $sidebar_width;
  background-color: white;
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.04);
  height: 100vh;
  position: fixed;
  z-index: 5;
  top: 0;
  transition: all 0.3s ease-in-out;
  @include media('tablet') {
    width: 0;
    opacity: 0;
    margin-left: -200px;
  }

  .top {
    width: 100%;
    height: 144px;
    justify-content: space-between;
    background: linear-gradient(180deg, #305599 0%, #0b327a 100%);
    display: flex;
    flex-direction: column;
    gap: 24;
    padding: 16px;
    span {
      span {
        color: $primary_400;
      }
    }
    h5 {
      color: $primary_25;
    }
    p {
      color: $primary_100;
    }
    .userInfo {
      p{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .menu {
    height: calc(100vh - 144px);
    overflow: auto;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .sidebarOptions {
      height: 44px;
      cursor: pointer;
      .icon {
        width: 30px;
      }
      padding: 6px 24px;
      display: flex;
      gap: 16px;
      align-items: center;

      &[data-variation='active'] {
        background-color: $terciary_25;
        color: $terciary_200;
      }
    }
  }

  .utilsMenu {
    transition: max-height 0.3s linear;
    max-height: 0px;
    overflow: hidden;
    .button {
      margin-left: 38px;
      padding-top: 12px;
      cursor: pointer;

      &.selected {
        text-decoration: underline;
      }
    }
    
    &[data-show="true"] {
      max-height: 140px;
    }
  }
}