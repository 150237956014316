@import '../../../styles/colors.scss';

@mixin absolute-zIndex($z) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: $z;
}

.div-container {
  padding-top: 3.5rem;
}

.div-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100vh;
  height: fit-content;
}

.div-left {
  display: flex;
  justify-content: center;
  height: 100%;
}

.div-select-options {
  padding-inline: 2rem;
  padding-top: 5.5rem;
  padding-bottom: 8.188rem;
}

.div-title-type {
  background: $primary_25;
  padding: 1.5rem;
  font-family: 'MuseoSansBold';
  border-radius: 1rem;
}

.title-h {
  font-family: 'MuseoSansBold';
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
  color: $primary_500;
}

.div-polo-svg-sticky {
  width: 100%;
  height: 40.752vw;
  position: sticky;
  top: 5rem;
}
.div-polo-svg-relative {
  width: 100%;
  height: 100%;
  position: relative;
}

.svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.div-polo-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(5);
}
.div-shadows-svg-absolute {
  width: 100%;
  height: 100%;

  @include absolute-zIndex(6);
}
.div-label-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(2);
}
.div-tags-svg-absolute {
  width: 100%;
  height: 100%;
  @include absolute-zIndex(8);
}

.font-polo {
  font-size: 3.22px;
}

.div-selected-container {
  display: flex;
  column-gap: 6rem;
  margin-top: 3rem;
  padding-left: 1.75rem;
  margin-bottom: 4.5rem;
}

@mixin grid_base_preview($rows) {
  display: grid;
  grid-template-columns: 50%50%;
  row-gap: 2rem;
  grid-template-rows: repeat($rows, 1fr);
}

.polo-div-grid {
  @include grid_base_preview(3);
  grid-template-areas:
    'Tela Logotipo'
    'Diseño_de_cuello Diseño_de_cuello'
    'Puños Abertura';
}
