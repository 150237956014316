@import '../../../styles/global.scss';
@import '../../../styles/colors.scss';

@mixin props {
  border-radius: 16px;
  padding: 0.4rem 1rem;
  margin-top: 0.4rem;
  color: #fff;
}


.collapsePanel{
    background-color: $primary_25!important;
  }

  .tag {
    border: none;
  
    font-size: 16px;
    .status {
      @include props;
  
     
      &[data-variation='8'] {
         background: $extra_150;;
      }
    
    }
  }