@import '../../../styles/colors.scss';
.booksTable {
  tr {
    cursor: pointer;
  }
}

.size-icons {
  width: 1rem;
  height: 1rem;
}

.edith-color {
  stroke: $terciary_200;
}

.trash-color {
  stroke: $extra_150;
}

.div-icons {
  display: flex;
  column-gap: 0.5rem;
}

.div-warning {
  background: $primary_25;
  padding-inline: 1.5rem;
  padding-block: 1rem;
}

.exclamation-triangle {
  stroke: $extra_150;
  width: 6.875rem;
  height: 6.875rem;
}

.div-center-warning {
  display: flex;
  justify-content: center;
}

.label-modal {
  font-size: 1rem;
  font-weight: 400;
  color: $primary_400;
  font-family: 'MuseoSansRegular';
}
